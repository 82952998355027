<template>
  <div>
    <el-drawer
      :with-header="false"
      :visible.sync="drawer"
      size="1100px"
      :direction="direction"
      :before-close="handleClose"
    >
      <div v-loading="loading">
        <el-tabs type="border-card" v-model="activeName" @tab-click="tabClick">
          <el-tab-pane label="基本信息" name="basic">
            <div class="section">
              <!-- <div class="title">用户信息</div> -->
              <ul class="list" v-if="productData.logo"> 
                <li class="item">
                  <div class="item-title" style="margin-left: 20px;">logo：</div>
                  <div class="imageBox" style="width: 100%;display: flex;">
                    <el-image  class="imageitem"  :src="productData.logo" :key="index" :preview-src-list="[productData.logo]"></el-image>
                  </div>
                </li>
              </ul>
              <ul class="list" v-if="productData.authorization"> 
                <li class="item">
                  <div class="item-title" style="margin-left: 20px;">授权书：</div>
                  <div class="imageBox" style="width: 100%;display: flex;">
                    <el-image  class="imageitem" v-for="(item,index) in productData.authorization.split(',')" :src="item" :key="index" :preview-src-list="[item]"></el-image>
                  </div>
                </li>
              </ul>

              <ul class="list" v-if="productData.qualifications"> 
                <li class="item">
                  <div class="item-title" style="margin-left: 20px;">资质：</div>
                  <div class="imageBox" style="width: 100%;display: flex;">
                    <el-image  class="imageitem" v-for="(item,index) in productData.qualifications.split(',')" :src="item" :key="index" :preview-src-list="[item]"></el-image>
                  </div>
                </li>
              </ul>
              <ul class="list">
                <li class="item">
                  <div class="item-title">ID：</div>
                  <div class="value">{{productData.id || '-'}}</div>
                </li>
                <li class="item">
                  <div class="item-title">供应商名称：</div>
                  <div class="value">{{productData.name || '-'}}</div>
                </li>
                <li class="item">
                  <div class="item-title">联系人：</div>
                  <div class="value">{{productData.contact || '-'}}</div>
                </li>
                <li class="item">
                  <div class="item-title">手机号码：</div>
                  <div class="value">{{productData.mobile || '-'}}</div>
                </li>
                <li class="item">
                  <div class="item-title">账号：</div>
                  <div class="value">{{productData.username || '-'}}</div>
                </li>
                <li class="item">
                  <div class="item-title">商品数：</div>
                  <div class="value">{{productData.goodsCount || '-'}}</div>
                </li>
                <li class="item">
                  <div class="item-title">状态：</div>
                  <div class="value">{{productData.state==1?'启用':'禁用'}}</div>
                </li>
              </ul>
  
            </div>
          </el-tab-pane>
          <el-tab-pane label="商品品牌列表" name="goods">
            <div class="section" style="margin-top: 50px;">
              <div class="list">
                <template>
                  <el-table :data="list" border class="tabNumWidth" size="mini">
                    <el-table-column align="center" label="logo" min-width="80">
                      <template slot-scope="scope">
                        <div class="demo-image__preview">
                          <el-image
                            style="width: 60px; height: 60px"
                            :src="scope.row.logo"
                          />
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="品牌名称" min-width="80" prop="name"></el-table-column>
                    <el-table-column align="center" label="类型" min-width="80" prop="type">
                      <template slot-scope="scope">
                        <div class="demo-image__preview">
                        {{scope.row.type==1?'代理':'自有'}}
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>
<script>

import {
  supplierDetail
} from "@/api/supplier/supplier";
export default {
  components: {},
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      productId: '',
      direction: 'rtl',
      activeName: 'basic',
      productData: {},
      list:[],
      merParams:[],
      sysParams:[]
    };
  },
  methods: {
    handleClose() {
      console.log('123')
      this.activeName = 'basic';
      this.$emit('closeDrawer');
    },
    getInfo(id) {
      this.loading = true;
      this.productId = id;
      supplierDetail(id).then(res => {
        this.loading = false;
        this.productData = res.data
        this.list = res.data.goodsBrandList
      })
    },

    pageChange(page) {
      this.recordForm.page = page
      this.getList()
    },
    handleSizeChange(val) {
      this.recordForm.limit = val
      this.getList()
    },
    tabClick(tab) {
   
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  padding: 20px 35px;
  .full {
    display: flex;
    align-items: center;
    .order_icon {
      width: 60px;
      height: 60px;
    }
    .iconfont {
      color: var(--prev-color-primary);
      &.sale-after {
        color: #90add5;
      }
    }
    .text {
      align-self: center;
      flex: 1;
      min-width: 0;
      padding-left: 12px;
      font-size: 13px;
      color: #606266;
      .title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #282828;
      }
      .order-num {
        padding-top: 10px;
        white-space: nowrap;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    .item {
      flex: none;
      width: 20%;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);      
      .title {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        color: #666666;
      }
    }
  }
}
.tabNumWidth{
  max-height: 350px;
  overflow-y: auto;
  &:before{
    display: none;
  }
}
.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
}
.section {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #303133;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .item {
    flex: 0 0 calc(100% / 3);
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    color: #606266;
    align-items: center;
    .item-title{
      width: 100px;
      text-align: right;
      margin: 10px;
      margin-left: 0;
    }
  }
  .item100{
    padding-left: 0;
    flex: 0 0 calc(100% / 1);
    padding-left: 0!important;
  }
  .contentPic{
    width: 500px;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: auto;
    /deep/img{
      max-width: 100%;
    }
  }
  .value {
    // flex: 1;
    .value-item {
      &::after{
        content: "/";
        display: inline-block;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
    }
    .value-temp{
       &::after{
        content: "、";
        display: inline-block;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
    }
    image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 12px 12px 0;
      vertical-align: middle;
    }
  }
}
.tab {
  display: flex;
  align-items: center;
  .el-image {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
/deep/.el-drawer__body {
  overflow: auto;
}

/deep/.ones th{
  background: #F0F5FF;
}
.iamgeBox{
  height: 100px;
  background-color: red;
}
.imageitem{
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

</style>
